@import "variables";

.ty_application_design {
  .ty_tabs_nav {
    .MuiTabs-flexContainer {
      gap: 10px;
      align-items: center;
      a {
        border: 1px solid rgba(222, 236, 240, 1);
        border-radius: 53px;
        color: var(--ty-secondary-color);
        letter-spacing: normal;
        text-transform: capitalize;
        @extend %ty_p_Font;
        color: var(--ty-secondary-color);
        position: relative;
        min-height: 40px;
        height: 40px;
        &.Mui-selected {
          height: 100%;
          position: relative;
          background: -webkit-linear-gradient(to bottom, #e2eef1, #f3f8fa);
          background: linear-gradient(to bottom, #e2eef1, #f3f8fa);
          font-weight: 600;
          left: 28px;
          z-index: 2;
          margin-right: 54px;
          color: rgba(66, 86, 108, 1);
          border: 0;
          border-radius: 0;
          min-height: 54px;
          height: 54px;
          cursor: pointer;
          overflow: visible;
          &::before {
            height: 100%;
            width: 60px;
            background-image: url("../../public/assets/images/tab_curved_01.png");
            background-position: bottom left;
            background-repeat: no-repeat;
            content: "";
            position: absolute;
            left: -44px;
            top: 0px;
            z-index: -1;
          }
          &:after {
            height: 100%;
            width: 60px;
            border-radius: 20px 10px 0px 0px;
            background-image: url("../../public/assets/images/tab_curved_02.png");
            background-position: bottom right;
            background-repeat: no-repeat;
            content: "";
            position: absolute;
            right: -44px;
            top: 0px;
          }
          &:nth-child(01) {
            &::before {
              border-radius: 20px 10px 0px 0px;
              left: -30px;
              background: linear-gradient(to bottom, #e2eef1, #f3f8fa);
            }
          }
          /*&::before {
                        height: 100%;
                        width: 60px;
                        border-radius: 20px 10px 0px 0px;
                        background: linear-gradient(to bottom, #e2eef1, #f3f8fa);
                        content: "";
                        position: absolute;
                        left: -32px;
                        top: 0px;
                        transform: skewX(-30deg);
                        z-index: -1;
                    }
                    &:after {
                        height: 100%;
                        width: 60px;
                        border-radius: 20px 10px 0px 0px;
                        background: linear-gradient(to bottom, #e2eef1, #f3f8fa);
                        content: "";
                        position: absolute;
                        right: -35px;
                        top: 0px;
                        transform: skewX(45deg);
                        z-index: -1;
                      }
                      &:nth-child(01) {
                        &::before {
                            border-radius: 20px 10px 0px 0px;
                            -webkit-transform: skewX(0deg);
                             transform: skewX(0deg);
                             left: -30px;
                        }
                      }
                      /*&:last-child {
                        &::before {
                           margin-left: 60px;
                        }
                      }*/
        }
      }
    }
  }
  .ty_tab-details {
    background: linear-gradient(180deg, #ddebef -60px, #ffffff 40px);
    border-top-right-radius: 10px;
  }
  //Stepper
  .MuiStepper-horizontal {
    margin-bottom: 25px;
    .MuiStepConnector-horizontal {
      border-color: #d9d9d9;
    }
    .MuiStep-horizontal {
      .MuiStepLabel-horizontal {
        gap: 10px;
        .MuiStepLabel-iconContainer {
          padding: 0px;
          border: 2px solid #d6dfe8;
          border-radius: 50%;
          width: 24px;
          height: 24px;
          display: flex;
          justify-content: center;
          align-items: center;
          svg {
            width: 10px;
            height: 10px;
            fill: #b7c5d5;
            .MuiStepIcon-text {
              display: none;
            }
          }
        }
        .MuiStepLabel-label {
          h6 {
            font-size: 12px;
            @media #{$mq-mobile-sm} {
              font-size: 10px;
            }
            text-transform: uppercase;
            letter-spacing: 2px;
            font-weight: 600;
          }
          h4 {
            @extend %ty_p_Font;
            color: var(--ty-secondary-color);
            text-transform: capitalize;
            color: #7b8999;
            text-align: left;
          }
        }
        .Mui-completed {
          svg {
            width: 24px;
            height: 24px;
            fill: rgba(53, 173, 151, 1);
          }
          h4 {
            color: rgba(66, 86, 108, 1);
          }
        }
        .Mui-active {
          svg {
            width: 18px;
            height: 18px;
            fill: var(--ty-primary-color);
          }
          h4 {
            font-weight: 600;
            color: var(--ty-primary-color);
          }
          .MuiStepLabel-iconContainer {
            border-color: #99b5d5;
          }
        }
      }
    }
  }
}
