
.main_container_month_display {
  @media #{$mq-tablet-lg} {
    overflow: auto;
    height: 100%;
  }
  .three-month-display {
    display: flex;
    width: 100%;
    flex: 0 100%;
    border-bottom: 1px solid #dbdbdd;
    margin-bottom: 16px;
    
    .separator_cst {
      height: calc(100% - 76px);
      width: 1px;
      background: #dbdbdb;
      margin-bottom: 16px;
      margin-top: 60px;
      @media #{$mq-tablet-lg} {
        display: none;
      }
    }
    .cst_arrow_calender {
      width: 20px;
      height: 20px;
      padding: 0;
      display: flex;
      align-items: center;
      justify-content: center;
        svg {
          width: 0.6em;
          height: 0.6em;
        }
  }
    .months-container {
      display: flex;
      justify-content: space-around;
      flex: 0 100%;
      @media #{$mq-tablet-lg} {
        flex-direction: column;
      }
      .month {
        //width: 30%;
        @media #{$mq-tablet-lg} {
          //width: 100%;
        }
        @media #{$mq-mobile-sm} {
          //width: 100%;
        }
        h3 {
          text-align: center;
          color: var(--ty-secondary-color);
          padding: 10px;
          font-weight: 600;
          margin: 0;
        }
        .week-days {
          display: flex;
          &.days_by_days {
            .week-day {
              display: none;
          }
          .unique_day {
            display: block !important;
          }
          }
          .week-day {
            flex: 1;
            color: var(--ty-secondary-color);
            opacity: 0.6;
            padding: 10px;
            @media #{$mq-mobile-sm} {
              padding: 5px 0px;
            }
            text-align: center;
          }
        }
        .days {
          display: grid;
          @extend %tyFont;
          grid-template-columns: repeat(7, 1fr);
          &.day_of_week {
            grid-template-columns: repeat(1, 1fr) !important;
            .disabled {
              display: none;
            }
          }
          .day {
            text-align: center;
            width: 40px;
            height: 40px;
            display: flex;
            margin: 4px auto;
            align-items: center;
            justify-content: center;
            cursor: pointer;
            color: var(--ty-secondary-color);
            flex-direction: column;
            .custom_calender_dot {
              padding: 3px;
              display: flex;
              margin-bottom: 1px;
              width: 14px;
              border-radius: 50px;
            }
            &.disabled {
              visibility: hidden;
              color: #ccc;
              pointer-events: none;
            }
            &.selected, &.today, &.selected-day {
              background-color: var(--ty-primary-color);
              color: white;
              border-radius: 50%;
            }
            &:hover {
              background-color: var(--ty-primary-color);
              color: white;
              border-radius: 50%;
            }
          }
        }
      }
    }
  } 
}


.Popup_Month_Display {
  .PopupMonthDisplay {
    margin-bottom: 0px !important;
    .month {
      
      @media #{$mq-mobile-sm} {
        width: 100%;
      }
      .day {
        justify-content: flex-start !important;
        @extend %tyFont;
        &:hover {
          background: #f1f7f9 !important;
          color: var(--ty-secondary-color) !important;
        }
      }
    }
    .separator_cst {
      margin-left: 50px;
      margin-right: 50px;
    }
  } 
}