@import "variables";

.ty_page_btn {
  padding-right: 32px;
  @media #{$mq-laptop} {
    padding-right: 5px;
  }
}
.ty_primary_btn,
.ty_secondary_btn,
.ty_tertiary_btn {
  @extend %ty_btn;
  padding: 10px 20px 11.5px 20px;
  // min-width: 108px;
  min-width: fit-content;
  @media #{$mediaQuery125} {
    min-width: auto;
    padding: 10px 15px 10px 15px;
  }
  @media #{$mq-laptop} {
    min-width: auto;
  }
  border-radius: 36px;
  box-shadow: none;
  @media #{$mq-tablet-lg} {
    min-width: auto;
  }
  @media #{$mq-tablet-lg} {
    width: 100%;
  }
  &:hover {
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.13);
  }
}
.ty_primary_btn {
  background: var(--ty-primary-color);
  border: 1px solid var(--ty-primary-color);
  color: var(--ty-white-color);
  &:hover {
    background: var(--ty-primary-hovercolor);
  }
}
.ty_secondary_btn {
  background: var(--ty-white-color);
  color: var(--ty-primary-color);
  border: 1px solid var(--ty-primary-color);
  &:hover {
    background: #ebf2f9;
  }
  &.disabled {
    border-color: #bdc3d4;
  }
}
.ty_tertiary_btn {
  background: transparent;
  color: var(--ty-primary-color);
  border: 1px solid transparent;
  &:hover {
    background: #ebf2f9;
    border-color: #ebf2f9;
  }
}
.ty_cancel_editing_btn {
  @extend %ty_btn;
  color: var(--ty-primary-color);
  box-shadow: none;
  background: transparent;
  border-radius: 36px;
  padding: 10px 20px 11.5px 20px;
  @media #{$mq-tablet-lg} {
    width: 100%;
  }
  &:hover {
    background: transparent;
    box-shadow: none;
  }
}
