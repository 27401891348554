@import "./styles/variables.scss";
@import url("./styles/global.scss");
@import "./styles/tycustom_style.scss";
@import "./styles/multicalendar.scss";

html,
body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  //overflow: hidden;
  height: 100%;
  min-height: 100%;
  max-height: 100%;
}

#root {
  height: 100%;
  min-height: 100%;
  max-height: 100%;
}

// For Modal
.MuiDialog-container,
.MuiPopover-paper {
  @import "./styles/table.scss";
  @import "./styles/buttons.scss";
  @import "./styles/forms.scss";
  @import "./styles/datatable.scss";
}
// For Modal

.ty_application_design {
  @import "./styles/loginPage.scss";
  @import "./styles/forms.scss";
  @import "./styles/buttons.scss";
  @import "./styles/table.scss";
  @import "./styles/datatable.scss";
  @import "./styles/badge.scss";

  @media #{$mq-4k} {
    background: linear-gradient(0deg, #e6fafd 0%, #eff7f7 100%);
  }

  background-image: url("../public/assets/images/main_bg.png");
  background-position: bottom right;
  background-repeat: no-repeat;
  height: 100%;
  min-height: 100%;
  max-height: 100%;
  @media #{$mq-mobile-sm} {
    overflow: hidden;
  }

  .page_heading_top {
    display: flex;
    .Inner_box {
      background: #fff;
      padding: 18px 18px 18px 32px;
      border-radius: 32px 0px 0px 0px;

      @media #{$mq-mobile-sm} {
        max-width: 260px;
      }

      overflow: hidden;
      text-wrap: nowrap;

      @media #{$mq-mobile-xs} {
        padding: 25px 1px 8px 20px;
      }

      p {
        @extend %ty_innerheading_Font;
        color: var(--ty-primary-color);
        text-transform: uppercase;
        letter-spacing: 2.4px;
        font-weight: 700;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
        font-family: var(--ty-font-family);
      }
    }

    &::after {
      content: "";
      background-image: url("../public/assets/images/curved_heading.png");
      background-position: center left;
      background-repeat: no-repeat;
      display: block;
      width: 81px;
      height: 69px;
    }
  }

  main {
    width: 100%;
    height: calc(100% - 70px);
    .main_Pages_box {
      background: rgba(199, 219, 229, 0.25);
      border-radius: 32px;
      height: calc(100% - 31px);
      margin: 0 40px 0px 24px;

      @media #{$mq-laptop} {
        margin: 0 24px 0px 24px;
      }

      @media #{$mq-tablet-lg} {
        margin: 0 24px 0px 24px;
      }
      .main_Pages_box_inner {
        height: 100%;

        .inner_wrapper_pages {
          height: 100%;

          .InnerPage_area {
            background: #fff;
            padding: 32px;
            @media #{$mediaQuery125} {
              padding: calc(32px / 2);
            }
            // padding-right: 10px;

            @media #{$mq-mobile-sm} {
              padding: 20px;
              overflow: scroll;
              // height: 100%;
            }

            border-radius: 0px 32px 32px 32px;
            height: calc(100% - 70px);
            width: 100%;

            .rc-scrollbars-view {
              // padding-right: 20px;

              @media #{$mq-mobile-sm} {
                // padding-right: 10px;
                overflow: scroll;
              }
            }
          }
        }
      }
    }
  }
}

/* styles.css  */
