@import "variables";

.ty_datagrid_container {
  background: #f1f7f9;
  padding: 16px;
  border-radius: 16px;
  box-shadow: none;
  border: none;
  --DataGrid-rowBorderColor: none;

  // .MuiDataGrid-footerContainer {
  //   margin-top: 20px;
  //   .MuiTablePagination-root {
  //     .MuiToolbar-root {
  //       .MuiTablePagination-input {
  //         width: auto;
  //       }
  //     }
  //   }
  // }
  .MuiTablePagination-root {
    .MuiToolbar-root {
      .MuiTablePagination-input {
        width: auto;
      }
    }
  }

  .MuiDataGrid-columnHeader {
    &:focus,
    &:focus-within {
      outline: none;
    }

    .MuiFormControl-root {
      .MuiFormLabel-root {
        display: none;
        // z-index: 9;
      }

      .MuiInputBase-root {
        padding: 0 13px;
        height: 32px;
        margin: 0;
        border: 1px solid #c2cbd6;
        border-radius: 8px;
        &:hover {
          border: 1px solid #9dacbd;
        }

        &:before,
        &:after {
          border: none;
        }
      }
      .MuiInputBase-root.Mui-focused {
        border: 1px solid var(--ty-primary-color);
        box-shadow: 0 0px 2px rgba(53, 109, 173, 1);
      }
    }
    .MuiDataGrid-columnHeaderTitleContainer {
      justify-content: space-between;
      flex-direction: row;
    }
    .MuiDataGrid-columnHeaderTitle {
      text-transform: uppercase;
      display: block;
      width: 100%;
      font-weight: 600;
      color: var(--ty-secondary-color);
    }
    &:focus {
      outline: none;
    }
  }
  .MuiDataGrid-virtualScrollerRenderZone {
    gap: 12px;
    .MuiDataGrid-row {
      background: #fff;
      border-radius: 20px;
      max-height: inherit !important;
      border-top-left-radius: 48px;
      border-bottom-left-radius: 48px;
      border-top-right-radius: 48px;
      border-bottom-right-radius: 48px;
      // overflow: hidden;
      display: inline-flex;
      align-items: center;
      box-shadow: none;
      &:nth-child(2n) {
        background: #d8e9ee;
      }
      &:last-child {
        margin-bottom: 28px;
      }
      &:hover {
        background: #d8e9ee;
        .MuiDataGrid-cell--pinnedLeft,
        .MuiDataGrid-cell--pinnedRight {
          background-color: inherit;
        }
      }
      .MuiDataGrid-columnSeparator {
        border-color: red;
        color: red !important;
      }
      .MuiDataGrid-cell--pinnedLeft {
        background-color: inherit;
        border-top-left-radius: 48px;
        border-bottom-left-radius: 48px;
        border-right: 1px solid #c3ced1;
      }
      .MuiDataGrid-cell--pinnedRight {
        background-color: inherit;
        border-left: 1px solid #c3ced1;
        border-top-right-radius: 48px;
        border-bottom-right-radius: 48px;
      }
      .MuiDataGrid-rowReorderCell.MuiDataGrid-rowReorderCell--draggable svg {
        color: #c6d9ec;
      }
    }
  }
  .MuiDataGrid-container--top [role="row"] {
    background-color: #f1f7f9;
    .MuiDataGrid-columnHeader--pinnedLeft {
      background-color: inherit;
      border-right-color: #c3ced1;
    }
    .MuiDataGrid-columnHeader--pinnedRight {
      background-color: inherit;
      border-left-color: #c3ced1;
    }
  }
  .MuiDataGrid-cell {
    border: 0;
    min-height: 48px;
    height: 48px;
    display: inline-flex;
    align-items: center;
    // justify-content: center;
    &:focus,
    &:focus-within {
      outline: none;
    }
    &:nth-child(01) {
      padding-left: 24px;
    }
    &:last-child {
      padding-right: 24px;
    }
  }
  .MuiDataGrid-cell.justifyCenter {
    justify-content: center;
  }
  .MuiDataGrid-filler--pinnedLeft,
  .MuiDataGrid-filler--pinnedRight {
    display: none;
  }
}

.ty_datagrid_container.kb_entrants {
  .Mui-disabled {
    -webkit-text-fill-color: var(--ty-secondary-color) !important;
    @extend %ty_p_Font;
    color: var(--ty-secondary-color);
  }
  .MuiCheckbox-root.Mui-disabled {
    color: #777;
  }
  .MuiCheckbox-root.Mui-checked {
    color: var(--ty-primary-color);
  }
  .MuiInputBase-root.Mui-disabled {
    background: none;
    font-size: 18px;
    font-weight: 500;
    input {
      padding: 0;
    }
    fieldset {
      border: none;
    }
  }
}
