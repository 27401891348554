@import "variables";

.authentication_screens {
  font-family: var(--ty-font-family);
  padding: 0 8.9%;
  &:after {
    content: "";
    position: absolute;
    background-color: #18629a;
    z-index: 1;
    top: 0;
    width: 100%;
    left: 0;
    height: 100vh;
    min-height: 700px;
    z-index: -1;
    mix-blend-mode: multiply;
  }
  .main_wrapper {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 80%;
    @media (max-width: 899px) and (orientation: landscape) {
      position: relative;
      top: 50px;
      left: auto;
      transform: none;
      width: 100%;
    }
  }
  .loginPageLeft {
    padding-right: 17%;
    @media #{$mq-tablet-md} {
      text-align: center;
      padding-right: 0;
    }

    img {
      @media #{$mq-tablet-lg} {
        height: 65px;
      }
    }
    p {
      font-size: 28px;
      color: var(--ty-white-color);
      font-style: italic;
      font-weight: 300;
      line-height: 33.61px;
      @media #{$mediaQuery125} {
        font-size: 20px;
      }
      @media #{$mq-laptop} {
        font-size: 20px;
      }
      @media #{$mq-tablet-lg} {
        text-align: left;
      }
      @media #{$mq-tablet-md} {
        font-size: 20px;
        text-align: center;
      }
    }
  }

  .page_heading_top {
    display: flex;
    .Inner_box {
      opacity: 0.9;
      padding: 18px 18px 12px 32px;

      @media #{$mq-tablet-lg} {
        padding: 20px 13px 8px 32px;
      }

      p {
        font-size: 32px;
        font-weight: 600;
        width: auto;
        @media #{$mq-laptop} {
          font-size: 24px;
        }
        @media #{$mq-tablet-lg} {
          font-size: 24px;
        }
      }
    }
    &::after {
      opacity: 0.9;
    }
  }

  .ty_primary_btn {
    padding: 15px 60px;
    .loginIcon {
      height: 12px;
      transition: all 0.3s linear 0s;
    }

    @media #{$mq-laptop} {
      padding: 12px 25px;
    }

    @media #{$mq-tablet-lg} {
      padding: 15px 20px;
      margin-top: 10px;
    }

    &:hover {
      .loginIcon {
        transform: translateX(10px);
      }
    }
  }

  .updatePwBtn {
    padding: 15px 26px;
  }

  .loginBox {
    background-color: #ffffffe5;
    padding: 65px 40px 80px;
    border-radius: 0 32px 32px 32px;
    @media #{$mediaQuery125} {
      padding: 30px 20px 40px;
    }
    @media #{$mq-laptop} {
      padding: 30px 20px 40px;
    }
    @media #{$mq-tablet-lg} {
      padding: 20px 25px 40px;
      width: auto;
    }
    input {
      -webkit-box-shadow: 0 0 0 30px white inset;
    }
  }

  .loginFields {
    margin-bottom: 40px;

    @media #{$mq-tablet-lg} {
      margin-bottom: 20px;
    }
  }

  .loginBoxBottom {
    display: flex;
    align-items: center;
    justify-content: space-between;

    @media #{$mq-tablet-lg} {
      display: block;
    }
  }
  video {
    position: absolute;
    top: 0;
    left: 0;
    min-width: 100%;
    z-index: -1;
    width: 100%;
    height: 100vh;
    min-height: 700px;
    object-fit: cover;
  }
  .alertBox {
    background-color: #049a251a;
    color: #049a25;
    border: 1px solid #abd3bb;
    padding: 11.5px 8px 11.5px 16px;
    border-radius: 8px;
    margin-bottom: 40px;
    line-height: 24px;

    @media #{$mq-tablet-lg} {
      margin-bottom: 20px;
    }
    .MuiAlert-message,
    .MuiAlert-action {
      padding: 0;
      display: flex;
      align-items: center;
      margin: auto;
    }
  }

  .dividerAnimation {
    height: 0.5px;
    background-color: #fff;
    margin-top: 24px;
    margin-bottom: 22px;
    animation: slideFromLeft 1.5s ease-in-out forwards;
  }

  @keyframes slideFromLeft {
    0% {
      width: 0;
      opacity: 0;
    }
    100% {
      width: 100%;
      opacity: 0.5;
    }
  }

  .animationLoginPage {
    opacity: 0;
    animation: fadeIn 1s ease-in-out 1s forwards;
  }

  @keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }

  .MuiTypography-caption {
    font-size: 13px;
    color: #af0808;
    margin-top: 6px;
  }

  .errorLabelColor {
    color: #af0808;
  }
  .forgotScreen {
    justify-content: end;
  }

  .backBtn {
    color: #42566c;
    padding: 0;
    .backIcon {
      height: 16px;
    }
  }
  h6 {
    color: #42566c;
    font-weight: 600;
    font-size: 22px;
    line-height: 26.4px;
  }

  .forgotPwText {
    p {
      margin-top: 12px;
      color: #42566c;
      font-size: 16px;
      line-height: 19.2px;
      font-weight: 400;
    }
  }

  .MuiInputBase-root {
    border: 1px solid transparent;
    &:hover {
      border: 1px solid #9dacbd;
    }
  }
  .MuiInputBase-root.Mui-focused {
    border: 1px solid var(--ty-primary-color);
    box-shadow: 0 0px 2px rgba(53, 109, 173, 1);
  }
}
