@font-face {
  font-family: 'Hellix';
  src: url('/public/assets/fonts/Hellix-Regular.woff2') format('woff2'),
      url('/public/assets/fonts/Hellix-Regular.woff') format('woff');
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Hellix';
  src: url('/public/assets/fonts/Hellix-Bold.woff2') format('woff2'),
      url('/public/assets/fonts/Hellix-Bold.woff') format('woff');
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Hellix';
  src: url('/public/assets/fonts/Hellix-ExtraBold.woff2') format('woff2'),
      url('/public/assets/fonts/Hellix-ExtraBold.woff') format('woff');
  font-weight: 800;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Hellix';
  src: url('/public/assets/fonts/Hellix-Black.woff2') format('woff2'),
      url('/public/assets/fonts/Hellix-Black.woff') format('woff');
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Hellix';
  src: url('/public/assets/fonts/Hellix-SemiBold.woff2') format('woff2'),
      url('/public/assets/fonts/Hellix-SemiBold.woff') format('woff');
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Hellix';
  src: url('/public/assets/fonts/Hellix-Thin.woff2') format('woff2'),
      url('/public/assets/fonts/Hellix-Thin.woff') format('woff');
  font-weight: 100;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Hellix';
  src: url('/public/assets/fonts/Hellix-Medium.woff2') format('woff2'),
      url('/public/assets/fonts/Hellix-Medium.woff') format('woff');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Hellix';
  src: url('/public/assets/fonts/Hellix-Light.woff2') format('woff2'),
      url('/public/assets/fonts/Hellix-Light.woff') format('woff');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

