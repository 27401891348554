@import "variables";

.ty-uppercase-label {
  text-transform: uppercase;
  display: block;
  width: 100%;
  margin-bottom: 10px;
}
.ty-uppercase-label_detail-view {
  margin-bottom: 17px;
}
.label_red_star {
  color: var(--ty-red-alert);
  font-weight: 800;
  margin-left: 5px;
}
// Toggle Style
// .MuiSwitch-colorPrimary {
//   margin-left: -6px;
// }

// Toggle Style

//Label

label {
  @extend %ty_btn;
  color: var(--ty-secondary-color);
  font-weight: 600;
  .MuiFormControlLabel-label {
    font-weight: 600;
  }
  .css-1ktcmrm-MuiTypography-root {
    @extend %ty_btn;
  }
}
.MuiTypography-p {
  @extend %ty_p_Font;
  color: var(--ty-secondary-color);
  a {
    color: var(--ty-primary-color);
    position: relative;
    transition: 0.2s;
    outline: none;
    text-decoration: none;
    &:after {
      content: "";
      background-color: var(--ty-primary-color);
      position: absolute;
      bottom: 0;
      left: 0;
      height: 1px;
      margin-top: 5px;
      width: 100%;
      transition: 0.2s;
    }
    /*&:hover {
                width: 100%;
                &:after {
	                width: 100%;
                }
            }*/
  }
}

//Subheading
.ty_form_heading {
  @extend %ty_p_Font;
  color: var(--ty-primary-color) !important;
  font-weight: 700;
  text-transform: uppercase;
  letter-spacing: 2.4px;
  font-style: normal;
  line-height: normal;
}

.ty_form_heading2 {
  @extend %ty_innerheading_Font;
  color: var(--ty-primary-color);
  font-weight: 700;
  text-transform: uppercase;
  letter-spacing: 2.4px;
  font-style: normal;
  line-height: normal;
}

//Basic Input
.MuiFormControl-root {
  width: 100%;
}

.MuiInputBase-root {
  border-radius: 12px;
  background: var(--ty-white-color);
  width: 100%;
  fieldset {
    border-color: #c2cbd6;
  }
  &:hover {
    .MuiOutlinedInput-notchedOutline {
      border: 1px solid #9dacbd;
    }
  }
  .MuiAutocomplete-input {
    padding: 0px !important;
  }
}
.MuiInputBase-root.Mui-focused {
  .MuiOutlinedInput-notchedOutline {
    border: 1px solid var(--ty-primary-color);
    box-shadow: 0 0px 2px rgba(53, 109, 173, 1);
  }
}
.MuiInputBase-root.Mui-focused.Mui-error,
.MuiInputBase-root.Mui-error {
  .MuiOutlinedInput-notchedOutline {
    border: 1px solid var(--ty-red-alert);
  }
  &:hover {
    .MuiOutlinedInput-notchedOutline {
      border: 1px solid var(--ty-red-alert);
    }
  }
}
// For Date Picker
.datePickerField {
  .MuiInputBase-root {
    .MuiInputBase-input {
      padding: 8.5px 14px;
    }
    & svg {
      height: 18px;
      width: 18px;
      color: #42566c;
    }
  }
}

// For Date Picker

// Checkbox and Radio Outline
// .MuiCheckbox-root,
// .MuiRadio-root {
//   color: #7f7f7f;
// }
// Checkbox and Radio Outline

//Text Area
textarea {
  border-radius: 12px;
  background: var(--ty-white-color);
  width: 100%;
  color: var(--ty-secondary-color) !important;
  font-family: var(--ty-font-family);
  padding: 10px;
  @extend %tyFont;
  border-color: #c2cbd6;
  outline: none;
  &:hover {
    border: 1px solid #9dacbd;
  }
  &:focus {
    border: 1px solid var(--ty-primary-color);
    box-shadow: 0 0px 2px rgba(53, 109, 173, 1);
  }
}
//Phone Input with Flag
.phn_input {
  .MuiInputBase-root {
    width: 100%;
    background: var(--ty-white-color);
    border: 1px solid #c2cbd6;
    border-radius: 12px;
    padding: 3px 8px;
    &:hover {
      border: 1px solid #9dacbd;
    }
  }
  .MuiInputBase-root.Mui-focused {
    border: 1px solid var(--ty-primary-color);
    box-shadow: 0 0px 2px rgba(53, 109, 173, 1);
  }
  .MuiPhoneNumber-flagButton {
    padding: 5px;
    svg {
      width: 24px;
    }
  }
  .MuiInput-underline {
    &::before {
      border: 0;
      display: none;
    }
    &::after {
      border: 0;
      display: none;
    }
  }
}
//Autocomlpete Chips
.MuiChip-root {
  background: rgba(226, 239, 243, 1);
  color: rgba(66, 86, 108, 1);
  font-weight: 600;
}
.MuiChip-root.ty_chip_pub {
  background-color: rgba(224, 255, 231, 1);
  width: 112px;
  color: rgba(5, 169, 41, 1);
  height: 32px;
  @extend %ty_p_Font;
  font-weight: 500;
  border: none;
}
.MuiChip-root.ty_chip_unpub {
  background-color: rgba(255, 226, 224, 1);
  width: 112px;
  color: rgba(255, 66, 66, 1);
  height: 32px;
  @extend %ty_p_Font;
  font-weight: 500;
  border: none;
}
.MuiChip-root.ty_chip_abnpub {
  background-color: rgba(219, 238, 255, 1);
  width: 112px;
  color: rgba(66, 164, 255, 1);
  height: 32px;
  @extend %ty_p_Font;
  font-weight: 500;
  border: none;
}

.customSelect {
  border-radius: 36px;
  color: var(--ty-primary-color);
  border-color: var(--ty-primary-color);
  text-transform: uppercase;
  font-weight: 500;
  width: auto;
  @extend %ty_btn;
  &:hover {
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.13);
    background: #ebf2f9;
    .MuiOutlinedInput-notchedOutline {
      border-color: var(--ty-primary-color);
    }
  }
  svg {
    color: var(--ty-primary-color);
  }
  .MuiOutlinedInput-notchedOutline {
    border-color: var(--ty-primary-color);
    @extend %ty_btn;
  }
}

// FOR NUMBER INPUT FIELD
.base-NumberInput-root.base--focused {
  border-color: #356dad;
}
// FOR NUMBER INPUT FIELD

// For No of Months Select
.ty_select_day {
  .MuiInputBase-input {
    padding: 8.5px 14px;
  }
}
// For No of Months Select
